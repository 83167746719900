import { isEmpty } from 'lodash';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import logo from './../assets/img/adzy.png';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { Pencil, Trash2, Eye, BarChart2, TrendingUp } from 'lucide-react';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
export const getErrorMsg = (err, errorMs = 'Error !!') => {
  const errObj = err?.response?.data;
  let error = errorMs;
  if (!isEmpty(errObj?.errors)) {
    error = (
      <ul>
        {errObj?.errors.map((item) => (
          <li>{item.reasonDesc}</li>
        ))}
      </ul>
    );
  } else if (errObj?.reasonDesc) {
    error = errObj?.reasonDesc;
  }
  return error;
};

export const commonApiError = 'Error! Please contact admin';

export const handleMsgOnForm = (err, enqueueSnackbar, setServerErrors) => {
  const errObj = err?.response?.data;
  if (!isEmpty(errObj?.errors)) {
    let temp = {};
    errObj?.errors.forEach((item) => {
      temp[item.field] = item.reasonDesc;
    });
    setServerErrors(temp);
  } else {
    enqueueSnackbar(errObj?.reasonDesc || commonApiError, {
      variant: 'error',
    });
  }
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case 'INR':
      return '₹';
    case 'USD':
      return '$';
    default:
      return '';
  }
};

export const numberFormatter = (num) => {
  if (typeof num !== 'number') {
    return '0'; // or handle the error in your preferred way
  }

  const numToCheck = Math.abs(num);
  const symbols = [
    { value: 1e3, symbol: 'K' }, // Thousand
    { value: 1e6, symbol: 'M' }, // Million
    { value: 1e9, symbol: 'B' }, // Billion
    { value: 1e12, symbol: 'T' }, // Trillion
  ];

  for (let i = symbols.length - 1; i >= 0; i--) {
    if (numToCheck >= symbols[i].value) {
      const newNumber = num / symbols[i].value;
      // Check if the decimal part is non-zero
      const formattedNumber = newNumber % 1 !== 0 ? newNumber.toFixed(1) : newNumber.toFixed(0);
      return `${formattedNumber}${symbols[i].symbol}`;
    }
  }

  // If the number is smaller than 1K or is an exact multiple of a larger unit, return it with one decimal place if non-zero
  return num % 1 !== 0 ? num.toFixed(2) : num.toFixed(0);
};

export const exportToPdf = async (
  data = [],
  fileName = 'default',
  productLogo = '',
  agencyLogo = '',
  filterData,
  orientation = 'landscape',
) => {
  if (!Array.isArray(data)) {
    throw new Error('Data should be an array');
  }

  let doc = new jsPDF(orientation, 'mm', 'a4');
  const maxImageHeight = 150; // Maximum height for images
  let currentPage = 0;

  const addLogosToPage = () => {
    if (productLogo) {
      const logoWidth = 35; // Adjust the size as needed
      const logoHeight = 10; // Adjust the size as needed
      const x = 10; // X position
      const y = 10; // Y position
      doc.addImage(productLogo, getImageFormat(productLogo), x, y, logoWidth, logoHeight); // Add product logo to the page
    }
    // if (agencyLogo) {
    //   const logoWidth = 35; // Adjust the size as needed
    //   const logoHeight = 10; // Adjust the size as needed
    //   const x = 175 - logoWidth; // Adjust X position for agency logo (right-aligned)
    //   const y = 10; // Y position for agency logo
    //   doc.addImage(agencyLogo, 'PNG', x, y, logoWidth, logoHeight); // Add agency logo to the page
    // }
  };
  const getImageFormat = (imageUrl) => {
    if (imageUrl.endsWith('.png')) {
      return 'PNG';
    } else if (imageUrl.endsWith('.jpg') || imageUrl.endsWith('.jpeg')) {
      return 'JPEG';
    }
    return 'PNG'; // Default to PNG if format is unknown
  };

  const addFilterData = () => {
    if (Object.keys(filterData).length === 0) {
      return 20; // Return default Y position if no filters
    }

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    let y = 30; // Starting Y position for filter data
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add agency logo if present
    // if (agencyLogo) {
    //   const logoWidth = 35; // Adjust the size as needed
    //   const logoHeight = 10; // Adjust the size as needed
    //   const x = 10; // X position for agency logo (left-aligned)
    //   doc.addImage(agencyLogo, getImageFormat(agencyLogo), x, y, logoWidth, logoHeight); // Add agency logo to the page
    //   y += logoHeight + 10; // Add space below the logo
    // }

    // Add filter data title left-aligned
    doc.setFontSize(20);
    doc.setFont('helvetica', 'bold');
    doc.text('Report:', 10, y); // X position at 10 to align left
    y += 15;

    // Add each filter data line
    doc.setFontSize(15);
    Object.entries(filterData).forEach(([key, value]) => {
      doc.setFont('helvetica', 'bold'); // Set font to bold for keys
      doc.text(`${key}:`, 10, y); // X position at 10 to align left
      doc.setFont('helvetica', 'normal'); // Set font to normal for values
      doc.text(value, 60, y); // X position for values, adjust if needed
      y += 10;
    });
    y += 10; // Add space before the first captured element
    return y; // Return the Y position after filter data
  };

  const addPageTitle = (title) => {
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(title, pageWidth / 2, 20, { align: 'center' }); // Add page title centered
  };

  const processPage = async (element, title) => {
    if (currentPage > 0) {
      doc.addPage();
      currentPage++;
      addLogosToPage();
    }

    // Add page title
    addPageTitle(title);

    try {
      const canvas = await html2canvas(element, { scale: 2 });
      const imageData = canvas.toDataURL('image/png');
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      const aspectRatio = imageWidth / imageHeight;

      let pdfImageWidth, pdfImageHeight;

      if (imageHeight > maxImageHeight) {
        pdfImageHeight = maxImageHeight;
        pdfImageWidth = pdfImageHeight * aspectRatio;
      } else {
        pdfImageWidth = imageWidth;
        pdfImageHeight = imageHeight;
      }

      const x = (pdfWidth - pdfImageWidth) / 2;
      const y = (pdfHeight - pdfImageHeight) / 2 + 10; // Adjust Y position to fit title and any additional spacing

      doc.addImage(imageData, 'PNG', x, y, pdfImageWidth, pdfImageHeight);
    } catch (error) {
      console.error('Error rendering element:', error);
      // Optionally, you can add a placeholder or skip this element
    }
  };

  // Add logos and filter data only on the first page
  addLogosToPage();
  const filterDataHeight = addFilterData();
  currentPage++;

  for (let i = 0; i < data.length; i++) {
    const element = document.querySelector(data[i].element);
    if (element) {
      const title = data[i].pageTitle || '';
      await processPage(element, title);
    }
  }
  const footer = (currentPage, totalPages) => {
    doc.setFontSize(10);
    doc.setTextColor(221, 102, 51);

    const mainText = `Powered by adzylytics - Page ${currentPage} of ${totalPages}`;
    const mainTextWidth =
      (doc.getStringUnitWidth(mainText) * doc.internal.getFontSize()) / doc.internal.scaleFactor;

    const mainTextX = (doc.internal.pageSize.getWidth() - mainTextWidth) / 2;
    const mainTextY = doc.internal.pageSize.getHeight() - 10;

    doc.text(mainText, mainTextX, mainTextY);

    const linkText = 'https://www.adzylytics.com';
    const linkTextWidth =
      (doc.getStringUnitWidth(linkText) * doc.internal.getFontSize()) / doc.internal.scaleFactor;

    const linkX = doc.internal.pageSize.getWidth() - linkTextWidth - 10;
    const linkY = doc.internal.pageSize.getHeight() - 10;

    doc.textWithLink(linkText, linkX, linkY, {
      url: linkText,
      target: '_blank',
    });
  };
  const totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    footer(i, totalPages);
  }
  await doc.save(`${fileName}.pdf`);
};

export const CHART_COLORS_LIST = ['#DD6733', '#5fdad6', '#725389', '#425177', '#2F4858', '#824FDF'];

export const CHART_COLOR = {
  IMPRESSION: '#DD6733',
  SPEND: '#824FDF',
  CLICK_COUNT: '#5fdad6',
  ADSERVEDCOUNT: '#E359E1',
};
export const backgroundColor = ['rgba(221, 102, 51, 0.2)'];
export const borderColorForCampaignTreadline = [
  'rgb(221, 102, 51)',
  '#2F4858',
  'rgb(242, 151, 39)',
];
export const borderColor = ['rgb(221, 102, 51)'];
export const Impressions = ['rgb(221, 102, 51)'];
export const Spend = ['rgb(242, 151, 39)'];
export const ClickCount = ['rgb(247, 126, 33)'];

export const getimpressionsData = (impressions) => {
  const maxClickCounts = Math.max(...impressions);
  return impressions.map((item) => (item / maxClickCounts) * 100);
};
export const getspendsData = (spends) => {
  const maxClickCounts = Math.max(...spends);
  return spends.map((item) => (item / maxClickCounts) * 100);
};
export const getclickCountsData = (clickCounts) => {
  const maxClickCounts = Math.max(...clickCounts);
  return clickCounts.map((item) => (item / maxClickCounts) * 100);
};
export const getcountsData = (counts) => {
  const maxClickCounts = Math.max(...counts);
  return counts.map((item) => (item / maxClickCounts) * 100);
};

export const calculateNumberOfDays = (startDate, endDate) => {
  const timeDifference = endDate - startDate;
  const numberOfDays = timeDifference / (1000 * 3600 * 24);
  return Math.max(0, Math.round(numberOfDays + 1));
};

export const getTruncateText = (text, length) => {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
};
