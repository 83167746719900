import React, { useState, useEffect } from 'react';
import getSessionData from './../../service-utils/session-util';
import { useSnackbar } from 'notistack';
import Services from '../../service-utils/services';
import { Link } from '@material-ui/core';
import './account.scss';
import Button from '@material-ui/core/Button';
import { useQuery, useMutation } from 'react-query';
import { NewTextField, Grid, Typography } from '../../common';
import { getCurrencySymbol, handleMsgOnForm } from '../../common/utils';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BASE_URL, PAYU_GATEWAY_URL, SELECTED_GATEWAY } from '../../common/constants';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ApplyVoucher from './apply-voucher';
import history from '../../history';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const PaymentIntegration = ({ handleBackButton, refetchGetBalance }) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory(); // Use the history hook to manipulate the URL
  let { agencyId, agencyCurrency } = getSessionData();
  const [billingDetails, setBillingDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  // const [amountTab, setAmountTab] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState('PAYU'); // Default view mode
  const isSelectedGatewayEnabled = SELECTED_GATEWAY === 'true';

  const { data: agencyData = {} } = useQuery(
    ['AGENCY_DATA', agencyId],
    async () => {
      const response = await Services.getAgency(agencyId);
      const receivedData = response?.data?.agencyDto;
      setBillingDetails({
        billAddress1: receivedData?.billAddress1 || '',
        billCity: receivedData?.billCity || '',
        billState: receivedData?.billState || '',
        billZip: receivedData?.billZip || '',
      });
      return receivedData;
    },
    { enabled: !!agencyId },
  );

  const getUserIP = async () => {
    const defaultIP = '0.0.0.0';
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Failed to fetch user IP:', error);
      return defaultIP;
    }
  };

  const { mutate: createOrder, isLoading: isLoadingPayNow } = useMutation(
    async (values) => {
      const { amount, notes } = values;
      const totalGst = calculateGST(amount);
      const totalAmount = calculateTotalAmount(values.amount);
      const payload = {
        amount: {
          amount: parseFloat(amount),
          currency: agencyCurrency,
        },
        totalAmount: {
          amount: parseInt(totalAmount),
          currency: agencyCurrency,
        },
        gst: parseInt(totalGst),
        notes: notes,
        additionalInfo: agencyId,
        initChannel: 'internet',
        ip: await getUserIP(), // Use a function to fetch the user's IP address dynamically
        userAgent: navigator.userAgent,
        acceptHeader: 'text/html', // Set accept header based on user agent or other available info
        browserTz: new Date().getTimezoneOffset(), // Timezone offset
        browserColorDepth: window.screen.colorDepth, // Explicitly use window.screen
        browserJavaEnabled: navigator.javaEnabled(),
        browserScreenHeight: document.body.clientHeight,
        browserScreenWidth: document.body.clientWidth,
        browserLanguage: navigator.language || navigator.userLanguage,
        browserJavascriptEnabled: true, // JavaScript is required for your function to execute
        gatewayEnum: selectedGateway,
        pgName: selectedGateway,
      };

      {
        const response = await Services.createOrder(agencyId, payload);
        if (selectedGateway === 'BILLDESK') {
          handleBillDeskForm(response?.data?.billDeskResponse);
        } else if (selectedGateway === 'PAYU') {
          handlePayUForm(response?.data);
        }
        return response.data;
      }
    },
    {
      onError: (err) => {
        const response = err?.response?.data;
        if (response) {
          if (response.errors && response.errors.length > 0) {
            response.errors.forEach((error) => {
              enqueueSnackbar(`${error.reasonDesc}`, { variant: 'error' });
            });
          }
        } else {
          enqueueSnackbar('An error occurred during payment.', { variant: 'error' });
        }
      },
      onSuccess: (data) => {
        setIsOpen(false); // Close the modal or perform any success actions
      },
    },
  );
  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      amount: '',
      notes: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .min(10000, `Amount must be at least ${getCurrencySymbol(agencyCurrency)}10000`)
        // .test('multiple-of-100', 'Amount must be a multiple of 100', (value) => value % 100 === 0)
        .required('Amount is required'),
    }),
    onSubmit: () => createOrder(values),
  });

  const handleBillDeskForm = (billDeskResponse) => {
    if (!billDeskResponse?.links || !billDeskResponse.links[1]) {
      enqueueSnackbar('Redirect link not available in the response.', { variant: 'error' });
      return;
    }

    const link = billDeskResponse.links[1];
    const form = document.createElement('form');
    form.name = 'sdklaunch';
    form.action = link.href; // The redirect URL
    form.method = 'POST';

    // Create hidden input fields for bdorderid, merchantid, and rdata
    const bdorderidInput = createHiddenInput('bdorderid', link.parameters.bdorderid);
    const merchantidInput = createHiddenInput('merchantid', link.parameters.mercid);
    const rdataInput = createHiddenInput('rdata', link.parameters.rdata);

    // Append the input fields to the form
    form.appendChild(bdorderidInput);
    form.appendChild(merchantidInput);
    form.appendChild(rdataInput);

    // Append the form to the body and submit it
    document.body.appendChild(form);
    form.submit();
  };

  const handlePayUForm = (response) => {
    const form = document.createElement('form');
    form.action = `${PAYU_GATEWAY_URL}`;
    form.method = 'POST';
    form.appendChild(createHiddenInput('key', response?.merchantKey));
    form.appendChild(createHiddenInput('txnid', response?.adzyOrder?.orderId));
    form.appendChild(createHiddenInput('productinfo', response?.productInfo));
    form.appendChild(createHiddenInput('amount', response?.adzyOrder?.totalAmount));
    form.appendChild(createHiddenInput('email', agencyData?.contactEmail));
    form.appendChild(createHiddenInput('firstname', agencyData?.agencyName));
    form.appendChild(createHiddenInput('surl', `${BASE_URL}/um-api/v1/pg/payu/verifyPayment`));
    form.appendChild(createHiddenInput('furl', `${BASE_URL}/um-api/v1/pg/payu/verifyPayment`));
    form.appendChild(createHiddenInput('phone', agencyData?.contactNo));
    form.appendChild(createHiddenInput('hash', response?.hashKey));

    document.body.appendChild(form);
    form.submit();
  };

  const createHiddenInput = (name, value) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
  };

  const calculateGST = (amount) => {
    const gst = (parseFloat(amount) * 18) / 100;
    const roundedGST = Math.floor(gst); // Round down to remove decimal part
    return isNaN(roundedGST) ? '0' : roundedGST.toString();
  };

  const calculateTotalAmount = (amount) => {
    const gst = calculateGST(amount);
    const totalAmount = parseFloat(amount) + parseFloat(gst);
    const roundedTotalAmount = Math.floor(totalAmount); // Round down to remove decimal part
    return isNaN(roundedTotalAmount) ? '0' : roundedTotalAmount.toString();
  };

  const areBillingDetailsEmpty = Object.values(billingDetails).some((detail) => !detail);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const method = params.get('method');
    if (method && ['PAYU', 'BILLDESK', 'VOUCHER'].includes(method.toUpperCase())) {
      setSelectedGateway(method.toUpperCase());
    }
  }, [location.search]);

  const handleViewModeChange = (mode) => {
    setSelectedGateway(mode);
    const params = new URLSearchParams(location.search);
    params.set('method', mode);
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <div>
      <div className="back-button-container">
        <button
          className="btn btn-md btn-link p-0 d-flex align-items-center"
          onClick={() => handleBackButton()}
        >
          <ArrowBackIcon />
          Back
        </button>
      </div>
      <div className="payment-form centered-form">
        <Typography className="mr-3" color="textPrimary" variant="h5">
          {' '}
          Add Balance
        </Typography>
        <div className="d-flex justify-content-between align-items-center">
          <div className="p-2">
            {isSelectedGatewayEnabled && (
              <div className="ml">
                <RadioGroup
                  row
                  name="selectedGateway"
                  value={selectedGateway}
                  onChange={(event) => handleViewModeChange(event.target.value)}
                >
                  <FormControlLabel
                    value="PAYU"
                    control={<Radio className="radio-btns" />}
                    label="PayU"
                  />
                  <FormControlLabel
                    value="BILLDESK"
                    control={<Radio className="radio-btns" />}
                    label="BillDesk"
                    className="ml"
                  />
                  <FormControlLabel
                    value="VOUCHER"
                    control={<Radio className="radio-btns" />}
                    label="Apply Voucher"
                    className="ml"
                  />
                </RadioGroup>
              </div>
            )}
            {selectedGateway != 'VOUCHER' ? (
              <form onSubmit={handleSubmit}>
                <Grid container item xs={12} spacing={2}>
                  <Grid className="form-field" item xs={12}>
                    <NewTextField
                      required
                      variant="outlined"
                      label="Enter Amount"
                      margin="normal"
                      id="amount"
                      name="amount"
                      type="number"
                      fullWidth
                      value={values.amount}
                      onBlur={handleBlur}
                      onChange={(event) => setFieldValue('amount', event.target.value)}
                      error={touched.amount && Boolean(errors.amount)}
                      helperText={touched.amount && errors.amount}
                      className="myCustomTextField textField"
                      inputProps={{ min: 100 }}
                    />
                  </Grid>
                  <Grid className="form-field" item xs={12}>
                    <NewTextField
                      variant="outlined"
                      margin="normal"
                      label="GST (18%)"
                      id="gst"
                      name="gst"
                      type="text"
                      fullWidth
                      disabled={true}
                      value={calculateGST(values.amount)}
                      InputProps={{
                        readOnly: true,
                      }}
                      className="myCustomTextField textField"
                    />
                  </Grid>
                  <Grid className="form-field" item xs={12}>
                    <NewTextField
                      variant="outlined"
                      label="Payable Amount"
                      margin="normal"
                      id="totalAmount"
                      name="totalAmount"
                      type="text"
                      fullWidth
                      disabled={true}
                      value={calculateTotalAmount(values.amount)}
                      InputProps={{
                        readOnly: true,
                      }}
                      className="myCustomTextField textField font-weight-bold"
                    />
                  </Grid>
                  <Grid className="form-field" item xs={12}>
                    <NewTextField
                      label="Notes"
                      variant="outlined"
                      margin="normal"
                      id="notes"
                      name="notes"
                      type="text"
                      fullWidth
                      value={values.notes}
                      onBlur={handleBlur}
                      onChange={(event) => setFieldValue('notes', event.target.value)}
                      error={touched.notes && Boolean(errors.notes)}
                      helperText={touched.notes && errors.notes}
                      className="myCustomTextField textField"
                    />
                  </Grid>
                </Grid>
                {areBillingDetailsEmpty && (
                  <div className="d-flex flex-column align-items-center mt-4">
                    <p className="text-danger mt-n2">
                      Billing details are required to create an Invoice.
                    </p>
                    <Link
                      href="/update-agency#1"
                      className="text-danger"
                      style={{ textDecoration: 'underline' }}
                    >
                      Click here
                    </Link>{' '}
                    <p className="text-danger mt-2"> to add the details</p>
                  </div>
                )}
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <Button
                    type="submit"
                    id="rzp-button1"
                    className="btn btn-primary mt-2 w-40 button-container"
                    size="medium"
                    // disabled={agencyCurrency === 'USD' && }
                    disabled={agencyCurrency === 'USD' || !!errors.amount || areBillingDetailsEmpty}
                  >
                    Pay Now
                  </Button>
                </div>
              </form>
            ) : (
              <ApplyVoucher
                refetchGetBalance={refetchGetBalance}
                handleBackButton={handleBackButton}
              />
            )}
          </div>
        </div>
      </div>

      {isLoadingPayNow && <OverlapLoader />}
    </div>
  );
};

export default PaymentIntegration;
