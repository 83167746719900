import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Card, CardContent, Typography, Button, Grid, Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Services from '../../service-utils/services';
import { getSessionData } from '../../service-utils';
import { getCurrencySymbol } from '../../common/utils';
import { Loader } from '../../common';

function ObjectiveSelection({ lineItemData = '', payload = {}, timezone, goToNextStep }) {
  const { campaignId = '', lineItemId = '', agencyId = '', advertiserId = '' } = payload;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCard, setSelectedCard] = useState(null); // Tracks selected card
  const [cardData, setCardData] = useState();

  const { agencyCurrency } = getSessionData();

  const { data: objectiveData = [], isLoading } = useQuery(
    ['OBJECTIVE_DATA', agencyId],
    async () => {
      const response = await Services.getMasterObjectives(agencyId);
      return response?.data?.masterObjectiveList || [];
    },
    { enabled: !!agencyId },
  );

  // Set default objective as "LEAD" when data is loaded
  useEffect(() => {
    if (objectiveData.length) {
      const defaultObjective = objectiveData.find((item) => item.name === 'LEAD');
      if (defaultObjective) {
        setSelectedCard(defaultObjective.id); // Set default selected card
      }
    }
  }, [objectiveData]);

  const handleObjectiveChange = (id) => {
    setSelectedCard(id);
    const selected = objectiveData.find((item) => item.id === id);
    setCardData(selected);
  };

  const handleSubmit = () => {
    if (cardData) {
      goToNextStep(cardData); // Pass the selected card data to the next step
    } else {
      enqueueSnackbar('Please select an objective before continuing.', { variant: 'warning' });
    }
  };

  const selectedObjective = objectiveData.find((item) => item.id === selectedCard);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Choose campaign objective
            </Typography>
          </div>

          <Grid container spacing={3}>
            {objectiveData.map((item) => (
              <Grid item xs={12} sm={4} key={item.id}>
                <Card
                  onClick={() => handleObjectiveChange(item.id)}
                  style={{
                    cursor: 'pointer',
                    padding: '16px',
                    borderRadius: '15px',
                    border: selectedCard === item.id ? '2px solid #dd6633' : '1px solid #ddd',
                    boxShadow:
                      selectedCard === item.id
                        ? '0 4px 10px rgba(63, 81, 181, 0.3)'
                        : '0 2px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s ease',
                    backgroundColor: selectedCard === item.id ? '#f5f5f5' : '#fff',
                    textAlign: 'center',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: 'bold',
                        color: selectedCard === item.id ? '#3f51b5' : '#333',
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      CPM:{' '}
                      {agencyCurrency === 'INR'
                        ? `${getCurrencySymbol(agencyCurrency)} ${item.cpmInr}`
                        : `${getCurrencySymbol(agencyCurrency)} ${item.cpm}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      CTR: {item.ctr.toFixed(2)}%
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <div className="mt-4 d-flex justify-content-end ">
            <Button
              disabled={!selectedCard || lineItemId} // Disable button if no objective is selected
              variant="contained"
              type="submit"
              className="btn btn-primary d-flex ml-4"
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ObjectiveSelection;
