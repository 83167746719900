import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table, SearchInput } from '../../components';
import { Button, Loader, NewSelect, TextField } from './../../common';
import AddIcon from '@material-ui/icons/Add';
import Services from '../../service-utils/services';
import getSessionData from './../../service-utils/session-util';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { CAMPAIGN_STATUS } from '../../libs/constants';
import { isEmpty, orderBy } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import { ConformationBox } from '../../components/conformationBox';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import CreateIcon from '../../components/create-icon/createIcon';
import {
  CloneButton,
  DeleteButton,
  EditButton,
  SearchButton,
} from '../../components/react-icons/ReactIcons';
import { IconButton } from '@material-ui/core';
import { Search } from 'lucide-react';
export const Campaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [advertiser, setAdvertiser] = useState([]);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [status, setStatus] = useState();
  const [deleteId, setDeleteId] = useState();
  const queryParams = queryString.parse(location.search);
  const { enabled: queryParamEnabled, name } = queryParams;
  let { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();

  const { data, isLoading, refetch } = useQuery(
    ['CAMPAIGN_DATA', pageLimit, pageNumber, agencyId, advertiserId, queryParamEnabled, name],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        enabled: queryParamEnabled,
        agencyId,
        name,
      };
      const response = await Services.campaignSearch(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    { enabled: !!agencyId && !!advertiserId },
  );

  const { data: allAdvertiser = [], isLoading: isLoadingAllAdv } = useQuery(
    ['ALL_ADVERTISERS', agencyId],
    async () => {
      const queryStringParams = {
        pageSize: 5000,
        pageNum: 0,
        agencyId,
      };
      const response = await Services.advertisersGetAll(queryString.stringify(queryStringParams));
      return response.data?.advertiserList;
    },
    { enabled: !!agencyId },
  );

  const handleAddCampaign = () => {
    history.push(`/advertiser/campaign-create`);
  };

  const tableData = useMemo(() => {
    return data?.campaignList || [];
  }, [data]);

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Campaign
          </div>
        ),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(allAdvertiser)) {
      const activeAdvertiser =
        orderBy(
          allAdvertiser?.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
            disabled: item.status,
          })),
          [(advertiser) => advertiser.label.toLowerCase()],
          ['asc'],
        ) || [];
      const selectedAdv = activeAdvertiser.filter(
        (item) => parseInt(item.id) === parseInt(advertiserId),
      )[0];
      const adv = selectedAdv || activeAdvertiser[0];
      setAdvertiser(adv);
      queryParamEnabled !== undefined &&
        setStatus(
          CAMPAIGN_STATUS.filter((item) => {
            return item.value === Boolean(queryParamEnabled);
          })[0],
        );
      setAdvertiserOptions(activeAdvertiser);
      if (isEmpty(selectedAdv) && !isEmpty(adv)) {
        history.push({
          pathname: '/advertiser/campaign',
          search: queryString.stringify({ advertiserId: adv.id }),
        });
      }
    }
  }, [allAdvertiser, history, advertiserId, queryParamEnabled]);

  const handleApply = useCallback(() => {
    if (!isEmpty(advertiser)) {
      let payload = {
        // advertiserId: advertiser?.id,
      };
      if (!isEmpty(status)) {
        payload = { ...payload, enabled: status?.value };
      }
      if (!isEmpty(searchText)) {
        payload = { ...payload, name: searchText || '' };
      }
      setPageNumber(1);
      history.push({
        pathname: '/advertiser/campaign',
        search: queryString.stringify(payload),
      });
    }
  }, [advertiser, status, searchText, history]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search]);

  const handleEdit = (id) => {
    if (id) {
      history.push(`/advertiser/campaign/${id}`);
    }
  };
  const handleClone = (id) => {
    if (id) {
      history.push(`/advertiser/campaign-clone/${id}`);
    }
  };
  const [serverErrors, setServerErrors] = useState({});

  const { mutate: handleDelete } = useMutation(
    async (id) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.campaignDelete(advertiserId, id, query);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Campaign deleted successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );
  const handleIcons = (row) => {
    const { id } = row;
    return (
      <div className="flex items-center gap-2">
        <CloneButton handleApply={() => handleClone(id)} />
        <EditButton handleEdit={() => handleEdit(id)} />
        <DeleteButton handleDelete={() => setDeleteId(id)} />
      </div>
    );
  };
  const manageButton = (row) => {
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-lg">
            <Dropdown.Item onClick={() => handleClone(row.id)}>Clone</Dropdown.Item>
            <Dropdown.Item onClick={() => handleEdit(row.id)}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setDeleteId(row.id)}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const getTableHeader = [
    //{ dataField: 'id', text: 'ID' },

    {
      dataField: '',
      formatter: (col, row) => {
        return (
          <div style={{ position: 'absolute', top: 0 }}>
            {row.expired && <div className="cross-tape">Expired</div>}
          </div>
        );
      },
    },
    {
      dataField: 'name',
      text: 'Campaign Name',
      formatter: (col, row) => (
        <a href={`/advertiser/line-item?advertiserId=${advertiserId}&campaignId=${row.id}`}>
          {col}
        </a>
      ),
    },
    { dataField: 'startDateTime', text: 'Start Date' },
    { dataField: 'endDateTime', text: 'End Date' },
    { dataField: 'timezone', text: 'Timezone' },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Enabled" className="alert-success" />
        ) : (
          <Chip label="Disabled" className="alert-danger" />
        ),
    },
    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => manageButton(row),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => handleIcons(row),
    },
  ];
  const handleButtonClick = () => {
    history.push('/advertiser/campaign-create');
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <NewSelect
            required
            options={CAMPAIGN_STATUS}
            value={status}
            onChange={(obj) => setStatus(obj)}
            placeholder={`Status`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          {/* <NewSelect
            required
            options={advertiserOptions}
            value={advertiser}
            onChange={(obj) => setAdvertiser(obj)}
            placeholder={`Advertiser`}
            isMulti={false}
            className="mt-n3 ml-3 "
          /> */}
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            startIcon={<SearchButton />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
        {/* <Button
          variant="contained"
          class="btn btn-primary d-flex btn-add"
          // startIcon={<AddIcon />}
          onClick={() => handleAddCampaign('1')}
        >
          New Campaign
        </Button> */}
        <div>
          <CreateIcon label="Create New Campaign" handleAdd={handleAddCampaign} />
        </div>
      </div>
      {isLoading || isLoadingAllAdv ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible line-item-table"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to new campaign"
          additionalText="Begin by creating a campaign, which will hold all your line items. Once your campaign is established, you can create and manage your line items with ease."
        />
      )}
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDelete(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this campaign?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};
