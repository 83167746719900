import { IconButton, Tooltip } from '@material-ui/core';
import {
  Pencil,
  Trash2,
  Eye,
  BarChart2,
  TrendingUp,
  ToggleLeft,
  ToggleRight,
  Send,
  XCircle,
  Copy,
  Settings,
  CircleUser,
  Search,
} from 'lucide-react';
import { Typography } from '../../common';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
export const EditButton = ({ handleEdit, disabled }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Edit</Typography>} arrow>
    <IconButton
      onClick={handleEdit}
      disabled={disabled}
      aria-label="edit"
      className="p-0 ml-3 IconFont react-icon"
    >
      <Pencil className="primary-color IconFont w-5 h-5" />
    </IconButton>
  </Tooltip>
);

export const ViewButton = ({ handleView }) => (
  <Tooltip title={<Typography className="TooltipFontSize">View</Typography>} arrow>
    <IconButton onClick={handleView} aria-label="view" className="p-0 ml-3 react-icon">
      <Eye className="w-5 h-5 IconFont primary-color" />
    </IconButton>
  </Tooltip>
);

export const DeleteButton = ({ handleDelete }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Delete</Typography>} arrow>
    <IconButton onClick={handleDelete} aria-label="delete item" className="p-0 ml-3 react-icon">
      <Trash2 className="w-5 h-5 deleteColor" />
    </IconButton>
  </Tooltip>
);

export const ResultButton = ({ handleView }) => (
  <Tooltip title={<Typography className="TooltipFontSize">View Result</Typography>} arrow>
    <IconButton onClick={handleView} aria-label="view result" className="p-0 ml-3 react-icon">
      <TrendingUp className="w-5 h-5 primary-color" />
    </IconButton>
  </Tooltip>
);

export const EnableButton = ({ handleToggle }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Enable User</Typography>} arrow>
    <IconButton
      onClick={handleToggle}
      aria-label="enable user"
      className="p-0 enabled-color ml-3 react-icon"
    >
      <ToggleOnIcon className="w-5 h-5 enabled-icon" style={{ fontSize: '30px' }} />
    </IconButton>
  </Tooltip>
);

export const DisableButton = ({ handleToggle }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Disable User</Typography>} arrow>
    <IconButton
      onClick={handleToggle}
      aria-label="disable user"
      className="p-0 deleteColor ml-3 react-icon"
    >
      <ToggleOffIcon className="w-5 h-5 deleteColor" style={{ fontSize: '30px' }} />
    </IconButton>
  </Tooltip>
);

export const ApplyButton = ({ handleApply }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Apply</Typography>} arrow>
    <IconButton onClick={handleApply} aria-label="apply" className="p-0 ml-3 react-icon">
      <Send className="w-5 h-5 primary-color IconFont" />
    </IconButton>
  </Tooltip>
);

export const RevokeButton = ({ handleApply, disabled }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Revoke</Typography>} arrow>
    <IconButton
      onClick={handleApply}
      aria-label="Revoke"
      disabled={disabled}
      className="p-0 ml-3 react-icon"
    >
      <XCircle className="w-5 h-5 primary-color IconFont" />
    </IconButton>
  </Tooltip>
);

export const CloneButton = ({ handleApply }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Clone</Typography>} arrow>
    <IconButton onClick={handleApply} aria-label="Clone" className="p-0 ml-3 react-icon">
      <Copy className="w-5 h-5 primary-color IconFont" />
    </IconButton>
  </Tooltip>
);

export const ManageButton = ({ handleApply, disabled }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Manage</Typography>} arrow>
    <IconButton
      onClick={handleApply}
      disabled={disabled}
      aria-label="Manage"
      className="p-0 ml-3 react-icon"
    >
      <Settings className="w-5 h-5 primary-color IconFont" />
    </IconButton>
  </Tooltip>
);

export const AccountButton = ({ handleApply }) => (
  <Tooltip title={<Typography className="TooltipFontSize">Account</Typography>} arrow>
    <IconButton onClick={handleApply} aria-label="Account" className="p-0 ml-3 react-icon">
      <CircleUser className="w-5 h-5 primary-color IconFont" />
    </IconButton>
  </Tooltip>
);

export const SearchButton = () => (
  <IconButton aria-label="Account" className="p-0  react-icon">
    <Search style={{ fontSize: '20px', color: 'white' }} className="w-5 h-5  IconFont" />
  </IconButton>
);
